import React, { Component } from "react";
import "firebase/auth";
import firebase from "./../firebase";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { db } from "../firebase";

//const db = firebase.firestore();

class Admin extends Component {
  state = {
    isSignedIn: false,
    hasPerms: false,
    large: [],
    medium: [],
    small: [],
    newName: "",
    newDescription: "",
    newImageFolder: "",
    newDate: "",
    newLocation: "",
  };
  uiConfig = {
    signInFlow: "popup",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccess: () => false,
    },
  };

  componentDidMount = () => {
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ isSignedIn: !!user });
    });
  };

  componentDidUpdate = () => {
    const context = this;
    if (this.state.isSignedIn) {
      let foundUid;
      const uid = firebase.auth().currentUser.uid;
      db.collection("admins")
        .get()
        .then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => {
            if (doc.data().uid === uid) {
              this.setState({ hasPerms: true });
            }
          });
        });
    }
    if (this.state.hasPerms && this.state.large.length == 0) {
      db.collection("large")
        .get()
        .then(function (querySnapshot) {
          let arr = [];
          querySnapshot.forEach(function (doc) {
            let tempArr = doc.data();
            tempArr.id = doc.id;
            arr.push(tempArr);
            context.setState({ large: arr });
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });

      //Medium
      db.collection("medium")
        .get()
        .then(function (querySnapshot) {
          let arr = [];
          querySnapshot.forEach(function (doc) {
            let tempArr = doc.data();
            tempArr.id = doc.id;
            arr.push(tempArr);
            context.setState({ medium: arr });
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });

      //Small
      db.collection("small")
        .get()
        .then(function (querySnapshot) {
          let arr = [];
          querySnapshot.forEach(function (doc) {
            let tempArr = doc.data();
            tempArr.id = doc.id;
            arr.push(tempArr);
            context.setState({ small: arr });
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    }
  };

  saveData = () => {
    this.state.large.forEach(function (item) {
      var itemRef = db.collection("large").doc(item.id);

      itemRef.set(item);
    });
    this.state.medium.forEach(function (item) {
      var itemRef = db.collection("medium").doc(item.id);

      itemRef.set(item);
    });
    this.state.small.forEach(function (item) {
      var itemRef = db.collection("small").doc(item.id);

      itemRef.set(item);
    });
    alert("saved");
  };

  //large
  updateInputNameL = (e) => {
    let arr = this.state.large;

    arr[e.target.name].name = e.target.value;
    this.setState({ large: arr });
  };

  updateInputDescriptionL = (e) => {
    let arr = this.state.large;

    arr[e.target.name].description = e.target.value;
    this.setState({ large: arr });
  };

  updateInputImageFolderL = (e) => {
    let arr = this.state.large;

    arr[e.target.name].imageFolder = e.target.value;
    this.setState({ large: arr });
  };
  // medium

  updateInputNameM = (e) => {
    let arr = this.state.medium;

    arr[e.target.name].name = e.target.value;
    this.setState({ medium: arr });
  };

  updateInputDescriptionM = (e) => {
    let arr = this.state.medium;

    arr[e.target.name].description = e.target.value;
    this.setState({ medium: arr });
  };

  updateInputImageFolderM = (e) => {
    let arr = this.state.medium;

    arr[e.target.name].imageFolder = e.target.value;
    this.setState({ medium: arr });
  };
  //small

  updateInputNameS = (e) => {
    let arr = this.state.small;

    arr[e.target.name].name = e.target.value;
    this.setState({ small: arr });
  };

  updateInputDescriptionS = (e) => {
    let arr = this.state.small;

    arr[e.target.name].description = e.target.value;
    this.setState({ small: arr });
  };

  updateInputImageFolderS = (e) => {
    let arr = this.state.small;

    arr[e.target.name].imageFolder = e.target.value;
    this.setState({ small: arr });
  };

  updateDateS = (e) => {
    let arr = this.state.small;

    arr[e.target.name].date = e.target.value;
    this.setState({ small: arr });
  };

  updateLocationS = (e) => {
    let arr = this.state.small;

    arr[e.target.name].location = e.target.value;
    this.setState({ small: arr });
  };

  updateDateM = (e) => {
    let arr = this.state.medium;

    arr[e.target.name].date = e.target.value;
    this.setState({ medium: arr });
  };

  updateLocationM = (e) => {
    let arr = this.state.medium;

    arr[e.target.name].location = e.target.value;
    this.setState({ medium: arr });
  };

  updateDateL = (e) => {
    let arr = this.state.large;

    arr[e.target.name].date = e.target.value;
    this.setState({ large: arr });
  };

  updateLocationL = (e) => {
    let arr = this.state.large;

    arr[e.target.name].location = e.target.value;
    this.setState({ large: arr });
  };

  updateInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  saveNewData = () => {
    if (
      this.state.newName === "" ||
      this.state.newDescription === "" ||
      this.state.newImageFolder === ""
    ) {
      alert("Fill the Fields");
    } else {
      const selectedSize = document.getElementById("selectNew").value;
      var itemRef = db.collection(selectedSize).doc();
      let item = {
        name: this.state.newName,
        description: this.state.newDescription,
        imageFolder: this.state.newImageFolder,
        date: this.state.newDate,
        location: this.state.newLocation,
      };
      itemRef.set(item);
    }
    this.setState({ newName: "", newDescription: "", newImageFolder: "" });
    alert("New item added");
  };

  signOut = () => {
    this.setState({ isSignedIn: false, hasPerms: false });
    firebase.auth().signOut();
  };

  render() {
    return (
      <div>
        {this.state.isSignedIn ? (
          <span>
            <div>Signed In!</div>
            <button onClick={this.signOut}>Sign out!</button>
            <h1>Welcome {firebase.auth().currentUser.displayName}</h1>
          </span>
        ) : (
          <StyledFirebaseAuth
            uiConfig={this.uiConfig}
            firebaseAuth={firebase.auth()}
          />
        )}

        {this.state.hasPerms ? (
          <div>
            got perms
            <h2>Large</h2>
            {this.state.large.map((item, index) => {
              return (
                <div key={item.id}>
                  <label>Name</label>
                  <input
                    name={index}
                    onChange={this.updateInputNameL}
                    value={item.name}
                  />
                  <label>Description</label>
                  <input
                    name={index}
                    onChange={this.updateInputDescriptionL}
                    value={item.description}
                  />
                  <label>Image Folder</label>
                  <input
                    name={index}
                    onChange={this.updateInputImageFolderL}
                    value={item.imageFolder}
                  />
                  <label>Date</label>
                  <input
                    name={index}
                    onChange={this.updateDateL}
                    value={item.date}
                  />
                  <label>Location</label>
                  <input
                    name={index}
                    onChange={this.updateLocationL}
                    value={item.location}
                  />
                </div>
              );
            })}
            <h2>Medium</h2>
            {this.state.medium.map((item, index) => {
              return (
                <div key={item.id}>
                  <label>Name</label>
                  <input
                    name={index}
                    onChange={this.updateInputNameM}
                    value={item.name}
                  />
                  <label>Description</label>
                  <input
                    name={index}
                    onChange={this.updateInputDescriptionM}
                    value={item.description}
                  />
                  <label>Image Folder</label>
                  <input
                    name={index}
                    onChange={this.updateInputImageFolderM}
                    value={item.imageFolder}
                  />
                  <label>Date</label>
                  <input
                    name={index}
                    onChange={this.updateDateM}
                    value={item.date}
                  />
                  <label>Location</label>
                  <input
                    name={index}
                    onChange={this.updateLocationM}
                    value={item.location}
                  />
                </div>
              );
            })}
            <h2>Small</h2>
            {this.state.small.map((item, index) => {
              return (
                <div key={item.id}>
                  <label>Name</label>
                  <input
                    name={index}
                    onChange={this.updateInputNameS}
                    value={item.name}
                  />
                  <label>Description</label>
                  <input
                    name={index}
                    onChange={this.updateInputDescriptionS}
                    value={item.description}
                  />
                  <label>Image Folder</label>
                  <input
                    name={index}
                    onChange={this.updateInputImageFolderS}
                    value={item.imageFolder}
                  />
                  <label>Date</label>
                  <input
                    name={index}
                    onChange={this.updateDateS}
                    value={item.date}
                  />
                  <label>Location</label>
                  <input
                    name={index}
                    onChange={this.updateLocationS}
                    value={item.location}
                  />
                </div>
              );
            })}
            <button onClick={this.saveData}>save</button>
            <h2>Add</h2>
            <label>Name</label>
            <input
              onChange={this.updateInput}
              name="newName"
              value={this.state.newName}
            />
            <label>Description</label>
            <input
              onChange={this.updateInput}
              name="newDescription"
              value={this.state.newDescription}
            />
            <label>Image Folder</label>
            <input
              onChange={this.updateInput}
              name="newImageFolder"
              value={this.state.newImageFolder}
            />
            <label>Date</label>
            <input
              name="newDate"
              onChange={this.updateInput}
              value={this.state.newDate}
            />
            <label>Location</label>
            <input
              name="newLocation"
              onChange={this.updateInput}
              value={this.state.newLocation}
            />
            <label>Size</label>
            <select id="selectNew">
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </select>
            <button onClick={this.saveNewData}>save</button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Admin;
