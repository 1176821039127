import React, { Component } from "react";
import "./HomePhotos.css";
import { storage } from "./../firebase";
import { db } from "./../firebase";
import ImageLoader from "react-loading-image";

let temArry = [];

class HomePhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      zoomedImg: "",
      showZoom: false,
      showZoomIndex: "",
      thumb: "",
      title: "",
      description: "",
    };
  }

  handleOnClick() {
    window.history.back();
  }

  prevCliick() {
    let index;
    if (this.state.showZoomIndex !== 0) {
      index = this.state.showZoomIndex + 1;
    } else {
      index = this.state.list.length - 1;
    }
    let img = this.state.list[index];
    this.setState({ zoomedImg: img, showZoomIndex: index });
  }

  nextClick() {
    let index;
    if (this.state.showZoomIndex !== this.state.list.length - 1) {
      index = this.state.showZoomIndex + 1;
    } else {
      index = 0;
    }
    let img = this.state.list[index];
    this.setState({ zoomedImg: img, showZoomIndex: index });
  }

  expandImage(evt, index) {
    this.setState({ zoomedImg: evt, showZoom: true, showZoomIndex: index });
    const objDiv = document.getElementById("toscroll");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  closeExpandImage() {
    this.setState({ showZoom: false });
  }

  componentDidMount() {
    temArry = [];
    const folderPath = new URLSearchParams(this.props.location.search).get(
      "path"
    );
    this.setState({
      showZoom: false,
      title: folderPath,
      description: folderPath,
    });
    const comp = this;
    var storageRef = storage.ref(folderPath);
    storageRef
      .listAll()
      .then(function (result) {
        comp.setState({ list: [] });
        result.items.forEach(function (imageRef) {
          displayImage(imageRef);
        });
      })
      .catch(function (error) {
        // Handle any errors
      });

    function displayImage(imageRef) {
      imageRef
        .getDownloadURL()
        .then(function (url) {
          temArry.push(url);
          comp.setState({ list: temArry });
          comp.setState({ thumb: temArry[0] });
        })
        .catch(function (error) {
          // Handle any errors
        });
    }

    //Get title and description
    let size = folderPath.split("/");
    var infoQuery = db
      .collectionGroup(size[0])
      .where("imageFolder", "==", folderPath);
    infoQuery.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        let title = doc.data().name ? doc.data().name : folderPath;
        let description = doc.data().description
          ? doc.data().description
          : folderPath;
        let date = doc.data().date ? doc.data().date : "";
        let location = doc.data().location ? doc.data().location : "";
        comp.setState({
          title: title,
          description: description,
          date: date,
          location: location,
        });
      });
    });
  }

  render() {
    return (
      <div className="bodyPhotos">
        {!this.state.showZoom ? (
          <div className="photos-header">
            <div className="containerPhotos">
              <div className="profilePhotos">
                <button
                  className="profile-edit-btn"
                  onClick={this.handleOnClick}
                >
                  Back
                </button>
                <div className="profile-image">
                  <ImageLoader
                    alt="thumImg"
                    className="thumImg"
                    src={this.state.thumb}
                    loading={() => (
                      <img
                        className="card-loader"
                        alt="loader"
                        src="./loader.gif"
                      />
                    )}
                  ></ImageLoader>
                </div>

                <div className="profile-user-settings">
                  <h1 className="profile-user-name">{this.state.title}</h1>
                </div>

                <div className="profile-bio">
                  <span className="profile-real-name">
                    {this.state.description}
                  </span>
                  <span className="dateLocationLbl">
                    {this.state.date} {this.state.location}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showZoom ? (
          <div className="zoomedPhotoContainer" id="zoomedContainer">
            <div className="imgContainer">
              {" "}
              <img
                className="zoomedPhoto"
                alt="zoomed"
                src={this.state.zoomedImg}
              ></img>
            </div>
            <div className="nextBtnContainer">
              <button className="prevBtn" onClick={() => this.prevCliick()}>
                {"<<"}
              </button>
              <button className="prevBtn" onClick={() => this.nextClick()}>
                {">>"}
              </button>
            </div>

            <button
              className="profile-edit-btn2"
              onClick={() => this.closeExpandImage()}
            >
              x
            </button>
          </div>
        ) : null}
        <div id="toscroll"></div>
        {!this.state.showZoom ? (
          <div className="photos-main">
            <div className="containerPhotos">
              <div className="galleryPhotos">
                {this.state.list.map((item, index) => {
                  return (
                    <div key={item} className="gallery-item">
                      <img
                        onClick={() => this.expandImage(item, index)}
                        src={item}
                        className="gallery-image"
                        alt={item}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default HomePhotos;
