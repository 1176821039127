import React, { Component } from "react";

class HomePage extends Component {
  state = {
    showCard: false,
  };

  showCard(value) {
    this.setState({ showCard: !value });
  }

  render() {
    return (
      <div className="App">
        {this.state.showCard ? (
          <div className="contactsPanel">
            <button
              className="closeContactsBtn"
              onClick={() => this.showCard(this.state.showCard)}
            >
              X
            </button>
            <img
              alt="cartao"
              src="/Cartão_Visita.jpg"
              className="contactsCard"
            ></img>
          </div>
        ) : null}
        <div className="header">
          <div className="logoContainer">
            <div className="logoContainer">
              <img
                alt="logo"
                className="headerLogo"
                src="/400dpiLogoCropped-copy.png"
              ></img>
            </div>
          </div>

          <div className="info">
            <h3 className="introText">
              What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. It has survived not only five centuries, but
              also the leap into electronic typesetting, remaining essentially
              unchanged.
            </h3>
            <a
              onClick={() => this.showCard(this.state.showCard)}
              className="logo"
            >
              Contactos
            </a>
            <div>
              <a href="#/gallery" className="logo">
                Portfólio
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
