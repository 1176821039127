import React, { Component } from "react";
import "./Home.css";
import Card from "./Card/Card";
import { storage } from "./../firebase";

class Home extends Component {
  state = {
    listLarge: [],
    listMedium: [],
    listSmall: [],
    filterAll: true,
    filterLarge: false,
    filterMedium: false,
    filterSmall: false,
    showCard: false,
  };

  showCard(value) {
    this.setState({ showCard: !value });
  }
  selectHandler(value) {
    document.getElementById("btnBig").classList.remove("btnSelected");
    document.getElementById("btnMedium").classList.remove("btnSelected");
    document.getElementById("btnSmall").classList.remove("btnSelected");
    let pageArg =
      window.location.protocol + "//" + window.location.host + "/#/gallery";
    switch (value) {
      case "L":
        this.setState({
          filterAll: false,
          filterLarge: true,
          filterMedium: false,
          filterSmall: false,
        });
        document.getElementById("btnBig").classList.add("btnSelected");
        pageArg = pageArg + "?type=L";
        break;

      case "M":
        this.setState({
          filterAll: false,
          filterLarge: false,
          filterMedium: true,
          filterSmall: false,
        });
        document.getElementById("btnMedium").classList.add("btnSelected");
        pageArg = pageArg + "?type=M";
        break;

      case "S":
        this.setState({
          filterAll: false,
          filterLarge: false,
          filterMedium: false,
          filterSmall: true,
        });
        document.getElementById("btnSmall").classList.add("btnSelected");
        pageArg = pageArg + "?type=S";
        break;

      default:
        this.setState({
          filterAll: true,
          filterLarge: false,
          filterMedium: false,
          filterSmall: false,
        });
        document.getElementById("btnBig").classList.add("btnSelected");
        break;
    }
    window.history.pushState({ path: pageArg }, "", pageArg);
  }
  componentDidMount() {
    async function getFolders(path) {
      const storageRef = storage.ref();
      var listRef = storageRef.child(path);
      let pathList = [];
      await listRef
        .listAll()
        .then(function (res) {
          res.prefixes.forEach((item) => {
            pathList.push(item);
          });
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
        });

      return pathList;
    }

    //Get large items
    getFolders("large").then((list) => {
      this.setState({ listLarge: list });
    });

    //Get Medium Items
    getFolders("medium").then((list) => {
      this.setState({ listMedium: list });
    });

    //Get Small Items
    getFolders("small").then((list) => {
      this.setState({ listSmall: list });
    });
    const size = new URLSearchParams(this.props.location.search).get("type");
    this.selectHandler(size);
  }

  handleOnClick() {
    window.location.href = "/#/";
  }

  render() {
    return (
      <div className="homeContainer">
        <div className="logoContainer2">
          <img
            alt="logo"
            className="headerLogo2"
            src="/400dpiLogoCropped-copy2.png"
            onClick={this.handleOnClick}
          ></img>
        </div>
        <a onClick={() => this.showCard(this.state.showCard)} className="float">
          <img
            alt="card"
            src="/buisnessCard.png"
            className="buisnessCard"
          ></img>
        </a>
        {this.state.showCard ? (
          <div className="contactsPanel">
            <button
              className="closeContactsBtn"
              onClick={() => this.showCard(this.state.showCard)}
            >
              X
            </button>
            <img
              alt="card2"
              src="/Cartão_Visita.jpg"
              className="contactsCard"
            ></img>
          </div>
        ) : null}
        <div className="selectContainer">
          <button
            id="btnBig"
            className="slectBtn"
            onClick={() => this.selectHandler("L")}
          >
            Grandes
          </button>
          <button
            id="btnMedium"
            className="slectBtn"
            onClick={() => this.selectHandler("M")}
          >
            Medias
          </button>
          <button
            id="btnSmall"
            className="slectBtn"
            onClick={() => this.selectHandler("S")}
          >
            Pequenas
          </button>
        </div>

        <div className="container">
          {this.state.filterAll || this.state.filterLarge ? (
            <div className="cards-container">
              {this.state.listLarge.map((item) => {
                return (
                  <Card
                    key={item.fullPath}
                    bColor="card-blue"
                    name={item.name}
                    folderPath={item.fullPath}
                    image={item.fullPath}
                  ></Card>
                );
              })}
            </div>
          ) : null}
          {this.state.filterAll || this.state.filterMedium ? (
            <div className="cards-container">
              {this.state.listMedium.map((item) => {
                return (
                  <Card
                    key={item.fullPath}
                    bColor="card-red"
                    name={item.name}
                    folderPath={item.fullPath}
                    image={item.fullPath}
                  ></Card>
                );
              })}
            </div>
          ) : null}
          {this.state.filterAll || this.state.filterSmall ? (
            <div className="cards-container">
              {this.state.listSmall.map((item) => {
                return (
                  <Card
                    key={item.fullPath}
                    bColor="card-yellow"
                    name={item.name}
                    folderPath={item.fullPath}
                    image={item.fullPath}
                  ></Card>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Home;
