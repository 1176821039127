import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyAA-tX-j21F7vjCWHCYHyMIryRpwDY8JAA",
  authDomain: "rui-rio-freire.firebaseapp.com",
  databaseURL: "https://rui-rio-freire.firebaseio.com",
  projectId: "rui-rio-freire",
  storageBucket: "rui-rio-freire.appspot.com",
  messagingSenderId: "342561393248",
  appId: "1:342561393248:web:3c832cc213f342daa91105",
  measurementId: "G-XNZQQD7P46",
});

const storage = firebase.storage();
let db = firebase.firestore();

export { storage, db, firebase as default };
