//import logo from "./logo.svg";
import "./App.css";
import React, { Component } from "react";
import Home from "./Home/Home";
import HomePhotos from "./CardPhotos/HomePhotos";
import HomePage from "./HomePage/HomePage";
import Admin from "./Admin/Admin";
import { Route, HashRouter } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="content">
          <HashRouter>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/gallery" component={Home} />
            <Route exact path="/CardPhotos" component={HomePhotos} />
            <Route exact path="/Admin" component={Admin} />
          </HashRouter>
        </div>
      </div>
    );
  }
}

export default App;
