import React, { Component } from "react";
import "./Card.css";
import { storage } from "./../../firebase";
import ImageLoader from "react-loading-image";
import { db } from "./../../firebase";

class Card extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      urlImage: "",
      togglePopup: false,
      fontSizeSt: { fontSize: "150px" },
    };
  }
  handleOnTileClick(path) {
    window.location.href = "#/CardPhotos/?path=" + path;
  }

  getThumbnail = (ref) => {
    const storageRef = storage.ref();
    var imageRefs = storageRef
      .child(ref)
      .list({ maxResults: 1 })
      .then((item) => {
        if (item.items[0]) {
          const imageRef = storage.ref(item.items[0].fullPath);
          imageRef.getDownloadURL().then((url) => {
            this.setState({ urlImage: url });
          });
        } else {
          this.setState({
            urlImage:
              "https://firebasestorage.googleapis.com/v0/b/rui-rio-freire.appspot.com/o/159668_large.gif?alt=media&token=7ddbbb19-cd80-4415-9efe-864fa88851dd",
          });
        }
      });
  };

  getTextWidth(name) {
    const size = parseInt(name.length);
    let fontSizeSw = 0;
    switch (true) {
      case size < 10:
        fontSizeSw = 50;
        break;
      case size < 20:
        fontSizeSw = 38;
        break;
      case size < 30:
        fontSizeSw = 30;
        break;
      case size < 120:
        fontSizeSw = 20;
        break;
      case size < 200:
        fontSizeSw = 15;
        break;
      default:
        fontSizeSw = 12;
        break;
    }
    this.setState({ fontSizeSt: { fontSize: fontSizeSw + "px" } });
  }

  getDetails(item) {
    let comp = this;
    //Get title and description
    let size = item.split("/");

    console.log(size, item);

    var infoQuery = db
      .collectionGroup(size[0])
      .where("imageFolder", "==", item);
    infoQuery.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        let title = doc.data().name ? doc.data().name : item;
        let description = doc.data().description
          ? doc.data().description
          : item;
        let date = doc.data().date ? doc.data().date : "";
        let location = doc.data().location ? doc.data().location : "";
        comp.setState({
          title: title,
          description: description,
          date: date,
          location: location,
        });
      });
    });
  }

  componentDidMount() {
    this.getTextWidth(this.props.name);
    this.getThumbnail(this.props.image);
    this.getDetails(this.props.folderPath);
  }
  render() {
    return (
      <div
        className={"card-container " + this.props.bColor}
        onClick={() => this.handleOnTileClick(this.props.folderPath)}
      >
        <ImageLoader
          alt={this.state.urlImage}
          className="card-img"
          src={this.state.urlImage}
          loading={() => (
            <img className="card-loader" alt="loader" src="./loader.gif" />
          )}
        ></ImageLoader>
        <label style={this.state.fontSizeSt} className="card-label-text">
          {this.state.title ? this.state.title : this.props.name}
        </label>
        <label className="card-label-date">
          {this.state.date ? this.state.date : ""}
        </label>
        <label className="card-label-date">
          {this.state.location ? this.state.location : ""}
        </label>
      </div>
    );
  }
}

export default Card;
